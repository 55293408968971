body {
  margin: 0;
  font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EDF2F2 !important;
}

p {
  font-family: "Montserrat" !important;
}

#root {
  background-color: #EDF2F2 !important;
}

.divider {
  width: 500px;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 500px) {
  .divider {
    width: 200px;
  }
}

.rc-slider-disabled {
  background-color: #fff !important;
  color: #000 !important;
}

.rc-slider-disabled .rc-slider-mark .rc-slider-mark-text {
  color: #999 !important;
}

.divider hr {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  border-top: 1px solid black;
}

.left {
  float: left;
}

.right {
  float: right;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.vh-100 {
  min-height: 100vh;
}

/* . {
  background-color: #47A836 !important;
  border: 1px solid #47A836 !important;
} */

.center-block {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}

.modal-stripe {
  min-width: 70vw;
}

.modal-65w {
  max-width: 1000px !important;
}

.modal-1300w {
  max-width: 1300px !important;
}

.modal-60w {
  max-width: 60% !important;
}

.modal-50w {
  max-width: 50% !important;
}

.form-label {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  font-family: 'Montserrat' !important;
}

.btn {
  border-radius: 100px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-align: center !important;
}

.btn-primary {
  background-color: #47A836 !important;
  border-color: #47A836 !important;
}

.btn-outline-primary {
  color: #47A836 !important;
  border-color: #47A836 !important;
}

.btn-outline-primary:hover {
  background-color: #47A836 !important;
  color: #fff !important;
}

h1 {
  color: #47A836 !important;
  font-size: 30px !important;
  font-weight: bold !important;
  font-family: "Montserrat" !important;
}

.overlay {
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.65) !important;
  z-index: 0;
}

.form-control {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid silver !important;
  margin: 0px !important;
  padding: 0px !important;
  outline: none !important;
  box-shadow: none !important;
}

.modal-backdrop {
  background-color: whitesmoke !important;
}

.modal-content {
  border: none !important;
}

#background-video {
  height: 100% !important;
  width: 100% !important;
  padding: none;
  margin: none;
  float: left;
  top: 0;
  left: 0;
  position: fixed; /* optional depending on what you want to do in your app */
  z-index: 0;
  object-fit: fill;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: center;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}

/* .rc-slider-mark-text-active + span {
  left: 95% !important;
} */

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

.rc-slider-mark-text {
  font-size: 12px;
  font-family: "Montserrat" !important;
  color: #020000 !important;
  width: 80px;
}

.nav-link.active {
  color: #47A836 !important;
  font-weight: bold;
  border-bottom: 1px solid #dee2e6 !important;
}

.nav-item {
  padding: 20px !important;
}

.nav-link {
  color: #999;
  font-size: 14px;
  border: none !important;
}

.nav-link:hover {
  color: #47A836 !important;
}

.btn {
  border-radius: 5px !important;
  font-weight: 500 !important;
  letter-spacing: 14;

  background: #ffffff !important;
  /* background-color: #47A836 !important; */
  background-color: 'transparent';
  text-decoration: none !important;
  font-family: 'Montserrat';
  border: 1.5px solid #47A836 !important;
  color: #47A836 !important;
}
.btn:hover {
  background: #278134 !important;
  border-color: #278134 !important;
  color: #fff !important;
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}